/* flex */

.f-flex {
	display: flex;
}

.f-flex-direction-horizontal {
	flex-direction: row;
}

.f-flex-direction-vertical {
	flex-direction: column;
}

.f-horizontal-align-center {
	justify-content: center;
}

.f-vertical-align-center {
	align-items: center;
}

/* size */

$size-small: 2.5pt;
$size-middle: 5pt;

/* custom */

* {
	user-select: none;
}

#root {
	height: 100vh;
	width: 100vw;
}

html {
	font-size: 10pt;
	font-family: "Courier New", Monospace;
}

p {
	margin: 0;
}

button {
	margin-top: $size-small;
	margin-bottom: $size-small;
	margin-left: $size-small;
	margin-right: $size-small;
}

.app {
	margin: auto;
	padding: $size-middle;
}

@media screen and (max-width: 600pt) {}

@media screen and (min-width: 600pt) {
	$container-width: 600pt;

	.app {
		width: $container-width;
	}
}

.footer {
	position: fixed;
	bottom: 0;

	margin-bottom: $size-middle;
}

.logo {
	width: 300pt;
	background: -webkit-linear-gradient(#000, #fff);
	background-clip: text;
	-webkit-text-fill-color: transparent;
}

.container {
	width: 100%;
	border: 1px black solid;
	background-color: rgb(248, 248, 248);
	margin: $size-small;
}

.dropzone {
	div {
		cursor: pointer;

		padding-top: 50pt;
		padding-bottom: 50pt;
	}

	p {
		text-align: center;
	}
}

.filesTable {
	width: 100%;

	td {
		padding: 0;

		white-space: nowrap;

		&.fit {
			width: 0%;
			white-space: nowrap;
		}
	}
}

.mr {
	margin-right: 0.5rem;
}